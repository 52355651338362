import { Typography, type TypographyProps } from '@mui/material';

const APP_BAR_TITLE = 'LIFT Assessments';

export function AppTitle({
  color,
  variant = 'component-bold-m',
  sx = {},
}: {
  color: 'white' | 'black';
  variant?: TypographyProps['variant'];
  sx?: TypographyProps['sx'];
}) {
  return (
    <Typography
      variant={variant}
      noWrap
      component="h1"
      sx={{
        color,
        letterSpacing: '-0.02em',
        fontWeight: 600,
        fontSize: { xs: '14px', md: '18px' },
        lineHeight: { xs: '16px', md: '24px' },
        ...sx,
      }}
    >
      {APP_BAR_TITLE}
    </Typography>
  );
}
